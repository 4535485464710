<template>
  <CCard>
    <CCardHeader>
      <h3>Ver Banner</h3>
    </CCardHeader>
    <CCardBody>
      <div class="form-group">
        <label>Posición:</label>
        <p>{{ registro.posicion }}</p>
      </div>

      <div class="form-group">
        <label>Título:</label>
        <p>{{ registro.titulo }}</p>
      </div>

      <div class="form-group">
        <label>Subtítulo:</label>
        <p>{{ registro.subtitulo }}</p>
      </div>

      <div class="form-group">
        <label>Texto:</label>
        <p>{{ registro.texto }}</p>
      </div>

      <div class="form-group">
        <label>Texto Link:</label>
        <p>{{ registro.texto_link }}</p>
      </div>

      <div class="form-group">
        <label>URL Link:</label>
        <p>{{ registro.url_link }}</p>
      </div>

      <div class="form-group">
        <label>Tipo de Enlace:</label>
        <p>{{ registro.tipo_enlace }}</p>
      </div>

      <div class="form-group">
        <label>Imágen:</label>
        <p>
          <img style="max-height: 100px; width: 100px" :src="urlImage + 'eco/small_'  + registro.imagen" alt=""/>&nbsp;
        </p>
      </div>

      <div class="form-group">
        <router-link to="/ecoBanners/List">
          <CButton color="secondary">
            <i class="cil-arrow-circle-left"></i>
            Regresar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        posicion: '',
        titulo: '',
        subtitulo: '',
        texto: '',
        texto_link: '',
        url_link: '',
        tipo_enlace: '',
        imagen: ''
      }
    }
  },

  methods: {
    async getRegistro() {
      this.id = this.$route.params.id;
      if (this.id !== '0') {
        let url = 'eco-banners/' + this.id
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
      }
    },
  },

  mounted() {
    this.getRegistro();
  }
}
</script>